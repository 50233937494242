////////////////////////////////////////////////////////////////////////////
// Fake Select
////////////////////////////////////////////////////////////////////////////

(function (global, document) {
  /**
   * Initialize all the fake select element
   */
  function init() {
    // Add a click listener to the document
    document.addEventListener('click', function (event) {
      // Get the clicked target
      let el = event.target;

      while (el) {
        if (el.classList) {
          // Check if top option was clicked
          if (el.classList.contains('fake-select__top-option')) {
            toggleFakeSelectOptions(el.parentNode);
            break;
          } else if (el.classList.contains('fake-select__inner-option')) {
            // Check if the inner option was clicked

            // Get the redirect url
            const selectedHref = el.getAttribute('data-url');

            if (selectedHref) {
              // Hide the inner option wrapper
              el.parentNode.style.display = 'none';

              // Redirect to the selected option
              global.location.href = selectedHref;
            }

            break;
          }
        }

        el = el.parentNode;
      }
    });
  }

  /**
   * Toggle the fake select option wrapper manually
   * @param {Element|string} fakeSelect The fake select element container or the element's id
   */
  function toggleFakeSelectOptions(fakeSelect) {
    // If the id was passed then select the element
    if (typeof fakeSelect === 'string') {
      fakeSelect = document.querySelector('#' + fakeSelect);
    }

    // If the fake select element is not passed or not found then return
    if (fakeSelect === null || typeof fakeSelect === 'undefined') {
      return;
    }

    // Select the inner option wrapper
    const innerOptionWrapper = fakeSelect.querySelector('.fake-select__inner-option-wrapper');

    const isOptionOpened = innerOptionWrapper.style.display === 'block';

    // Hide and show the inner options
    if (isOptionOpened) {
      fakeSelect.classList.remove('fake-select--is-open');
      innerOptionWrapper.style.display = 'none';
      document.onclick = null;
    } else {
      fakeSelect.classList.add('fake-select--is-open');
      innerOptionWrapper.style.display = 'block';
      document.onclick = function () {
        toggleFakeSelectOptions(fakeSelect);
      };
    }
  }

  // Initialize all the fake select elements
  vm.onload(init);

  // Export the class for global use
  global.FakeSelect = {
    init: init,
    toggleOptions: toggleFakeSelectOptions,
  };
})(window, document);
